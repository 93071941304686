body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans Variable', sans-serif;
}

.ql-toolbar.ql-snow {
  border: 2px solid #E4E5E7 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-width: 0 !important;
}
.ql-container {
  border: 2px solid #E4E5E7 !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 200px !important;
}